import React from 'react'
import BannerCarousel from './BannerCarousel'
import Main from './Main'

function Home() {
  return (
    <>
    <BannerCarousel />
    <Main />
    </>
  )
}

export default Home